import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useFormik } from 'formik'
import { addDays, subDays } from 'date-fns'
import { default as DatePicker } from 'react-datepicker'

import {
  Flex,
  Input,
  Button,
  Text,
  Stack,
  SimpleGrid,
  Textarea,
  FormControl,
  FormLabel,
  FormErrorMessage,
} from '@chakra-ui/react'

import 'react-datepicker/dist/react-datepicker.css'

function FormQuote({
  hideOnSuccess,
  onSubmit,
  name,
  email,
  phone,
  date,
  time,
  successText,
  buttonText,
}) {
  const [success, setSuccess] = useState(false)
  const formik = useFormik({
    initialValues: { name: '', email: '', phone: '', date: null, checkOut: null },
    validate: (values) => {
      const errors = {}

      if (!values.email) {
        errors.email = email.required
      } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
        errors.email = email.invalid
      }

      if (!values.name) {
        errors.name = name.required
      }

      return errors
    },
    onSubmit: (values, { setSubmitting }) => {
      onSubmit(values, (success) => setSuccess(success))
      setSubmitting(false)
    },
  })

  if (hideOnSuccess && success) {
    return (
      <Flex padding={10} justify="center">
        <Text fontWeight="bold" fontSize="xl">
          {successText}
        </Text>
      </Flex>
    )
  }

  const {
    handleBlur,
    handleChange,
    handleSubmit,
    touched,
    errors,
    values,
    isSubmitting,
    setFieldValue,
  } = formik

  return (
    <form onSubmit={handleSubmit}>
      <Stack spacing={6}>
        <FormControl isRequired isInvalid={errors.name && touched.name}>
          <FormLabel htmlFor="name">{name.label}</FormLabel>
          <Input
            variant="flushed"
            type="name"
            id="name"
            value={values.name}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          <FormErrorMessage>{errors.name}</FormErrorMessage>
        </FormControl>

        <FormControl isRequired isInvalid={errors.email && touched.email}>
          <FormLabel htmlFor="email">Email</FormLabel>
          <Input
            variant="flushed"
            type="email"
            id="email"
            aria-describedby="email-helper-text"
            value={values.email}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          <FormErrorMessage>{errors.email}</FormErrorMessage>
        </FormControl>

        <FormControl isInvalid={errors.phone && touched.phone}>
          <FormLabel htmlFor="phone">{phone.label}</FormLabel>
          <Input
            variant="flushed"
            type="phone"
            id="phone"
            value={values.phone}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          <FormErrorMessage>{errors.phone}</FormErrorMessage>
        </FormControl>

        <FormControl isInvalid={errors.date && touched.date}>
          <FormLabel htmlFor="date">{date.label}</FormLabel>
          <DatePicker
            id="date"
            autoComplete="off"
            customInput={<Input variant="flushed" />}
            selected={values.date}
            minDate={addDays(new Date(), 1)}
            maxDate={values.checkOut === null ? null : subDays(values.checkOut, 1)}
            onChange={(e) => setFieldValue('date', e)}
            onBlur={handleBlur}
          />
          <FormErrorMessage>{errors.date}</FormErrorMessage>
        </FormControl>

        <FormControl isInvalid={errors.time && touched.time}>
          <FormLabel htmlFor="time">{time.label}</FormLabel>
          <Input
            variant="flushed"
            type="time"
            id="time"
            value={values.time}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          <FormErrorMessage>{errors.time}</FormErrorMessage>
        </FormControl>

        <Button colorScheme="primary" size="lg" type="submit" isDisabled={isSubmitting}>
          {buttonText}
        </Button>
      </Stack>
    </form>
  )
}

FormQuote.propTypes = {
  hideOnSuccess: PropTypes.bool,
  onSuccess: PropTypes.func,
}

FormQuote.defaultProps = {
  hideOnSuccess: false,
  onSuccess: () => {},
}

export default FormQuote
